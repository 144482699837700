export const CONTENTS_DESCRIPTION = {
  DEFAULT:
    "gameofus(ゲームオブアス)は無料で遊べるブラウザゲームを提供しています。OBSでも使える配信用のツールやお祝い用のメッセージカード機能もご用意しています。どのコンテンツもダウンロードなし、インストールなしでご利用できます。",

  GAMES_TBH:
    "ヒーローを目指すフリーターの主人公を育てる育成ゲーム。独特なSEも見どころだが、実は多すぎるエンディングも見どころの一つ。",
  GAMES_WAS:
    "人間である主人公が魔王を目指して世界を支配していくRPG。ゴブリンやエルフなど個性豊かなキャラクターたちとの戦闘・会話を楽しめます。",
  GAMES_MP: "壺にお金を貯めるゲームです。",
  GAMES_WIL:
    "田舎育ちの青年が聖騎士団に入り、世界を救うRPG。キャラクターの配置が戦闘の勝敗を分ける！？キャラクター同士の掛け合いもお楽しみください。",

  TOOLS_STOPWATCH:
    "ブラウザで時間を計れます。一時停止機能もあります。OBSでは背景透過するので、配信の邪魔になりません！",
  TOOLS_TRANSLATE: "いくつかの言語を翻訳することができます。",

  BLESSINGS_NEWYEAR:
    "カスタムした年賀状を高速、簡単に作成！新年の挨拶にご利用ください。",
} as const;
